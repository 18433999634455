/*-----------------------------------------------------------------------------------

    Template Name: Inniver theme
    Description: This is Inniver theme
    Author: Krzysztof Furmaniak
    Author URI: 

-----------------------------------------------------------------------------------

01. Theme colors variables
02. 

*/

/**=====================
     01. Theme colors variables
==========================**/

$inniver-revenue-color: #ff6018;            // Total inniver revenue
$inniver-profit-color: #5dab47;             // Total inniver profit
$inniver-visitor-color: #f6a821;            // visitors number
$inniver-conversion-color: #936413;         // conversions number
$inniver-click-color: #c4861a;              // clicks number

$inniver-pendingpayout-color: #c3cf2e;      // pending payout
$inniver-fee-color: #ff0000;                // fee in total
$inniver-paidtoday-color: #3c891f;          // total to paid today
$inniver-epc-color: #8c8c8c;                // epc
$inniver-cr-color: #a8c2bb;                 // cr
$inniver-alloffers-color: #0d0394;          // all offers
$inniver-activeoffers-color: #9801ee;       // all active offers

/**=====================
     02. Admin-Dashboard; Admin-Finance-Payments, Publisher-Payments
==========================**/

.inniver-bg-revenue-color {
  background-color: $inniver-revenue-color !important;
}

.inniver-font-revenue-color {
    color: $inniver-revenue-color !important;
  }

.inniver-bg-profit-color {
  background-color: $inniver-profit-color !important;
}

.inniver-bg-paidtoday-color {
    background-color: $inniver-paidtoday-color!important;
  }

/**=====================
    03. Publisher
==========================**/

.inniver-bg-fee-color{
    background-color: $inniver-fee-color !important;
}

.inniver-bg-pendingpayout-color{
    background-color: $inniver-pendingpayout-color !important;
}

.inniver-bg-visitor-color{
    background-color: $inniver-visitor-color !important;
}

.inniver-bg-click-color{
    background-color: $inniver-click-color !important;
}

.inniver-bg-conversion-color{
    background-color: $inniver-conversion-color !important;
}

.inniver-bg-epc-color{
    background-color: $inniver-epc-color !important;
}

.inniver-bg-cr-color{
    background-color: $inniver-cr-color !important;
}